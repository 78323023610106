import React from 'react'
import { Row, Col, Carousel } from 'antd';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

const locales = {
    en: { title: 'English' },
    ar: { title: 'Arabic' },
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const HomePageEn = () => {
    const { t } = useTranslation();
    return (
        <div style={{ direction: 'ltr' }}>
            <div
                style={{ backgroundColor: "", height: "85vh", textAlign: "center" }}
                block="true"
            >

                <Carousel
                    autoplay
                    autoplaySpeed={2000}
                    effect="scrollx"
                    draggable="true"
                    dotPosition="bottom"
                >
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/0-banner.jpg" alt="Slide 1-mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/0-banner.jpg" alt="Slide 1" />
                    </div>

                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/1-banner.jpg" alt="Slide 1-mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/1-banner.jpg" alt="Slide 1" />
                        <Link to="/En/Stocks">
                            <button className="custom-button">اكتتب الآن</button>
                        </Link>
                    </div>
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/2-banner.jpg" alt="Slide 2 mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/2-banner.jpg" alt="Slide 2" />
                        <Link to="/En/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                    <div className="image-box">
                        <Col s xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/3-banner.jpg" alt="Slide 3 mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/3-banner.jpg" alt="Slide 3" />
                        <Link to="/En/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/5-banner.jpg" alt="Slide 4 mobile" />
                        </Col>
                        <img className='boxing-image' style={{ objectPosition: 'top left' }} src="/../images/5-banner.jpg" alt="Slide 4" />
                        <Link to="/En/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                </Carousel>

            </div>




            <div className='first-subject'>
                <Row name="#1">
                    <Col xs={24} sm={8} md={5}><div style={{ textAlign: 'center' }}>
                        <img style={{ maxWidth: '300px', width: '100%' }} src='/../images/head.png' alt='h' />
                    </div>
                    </Col>
                    <Col sm={16} md={19}><div style={{ textAlign: 'left' }}>
                        <p style={{ fontSize: '1.4em', padding: '35px', fontWeight: '400' }}>"The main concern of the good Federal March since its announcement; is taking care of the citizen and creating all conditions to ensure his security, prosperity, and stability. To this end, our wise leadership didn't spare any effort or money until it was able, with Allah's grace to achieve this goal"</p>
                        <p style={{ color: '#9B7A39', fontWeight: '400', fontSize: '1.3em', padding: '0 0 0 35px' }}>His Highness Sheikh Mohammed bin Zayed Al Nahyan, President of the United Arab Emirates</p>
                    </div>
                    </Col>
                </Row>
                <Row name="#2" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>The purpose of the cooperative:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>Based on the Government's vision to develop the housing sector in the country for a new phase of sustainable development; in which the efforts and roles are integrated and the parties cooperate to improve the housing situation and support its requirements in accordance with a national vision.
                            The first and final objective of this vision is the prosperity and happiness of citizens who are the priority of the next phase of national development.
                            Emirates Cooperative for Housing and Real Estate Development has been launched to develop and build villas, buildings, residential buildings commercial buildings, and facilities with a capital of 25 million, Based in Abu Dhabi with branches across the UAE.
                        </p>
                    </div>
                </Row>
                <Row name="#3" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>The most important features of the cooperative:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>1.	Its membership is limited to UAE citizens.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>2.	The cooperative's capital shares are indeterminate in number and can be continuously increased whenever needed.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>3.	The cooperative's customers are its members, which saves a lot of marketing efforts to sell its services.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>4.	The members of the cooperative benefit from their contribution to the cooperative through the dividends of their shares as well as the Return on purchases distributed to them according to their dealings with the cooperative.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>5.	The supervision of the Ministry of Economy and the Department of Economic Development on the cooperative, which increases its discipline, and transparency of its procedures, and makes it follow the best practices.</p>
                    </div>
                </Row>
                <Row name="#4" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Services provided by the Cooperative to its members:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• Building its members' villas and resides on grant land or land members own.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• Developing commercial and residential real estate projects and offering them to members.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• Manage, lease, and maintain buildings, apartments, villas, and business units.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• Trying to arrange the necessary residential land for housing for members.</p>
                    </div>
                </Row>
                <Row name="#5" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Cooperative's objectives:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>1.	Contributing to the construction of housing on the land granted to citizens by the Government at the best cost.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>2.	Provide citizens with affordable apartments, villas, and residential and commercial units at suitable and competitive prices.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>3.	Contributing to feasible real estate investment opportunities for citizens.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>4.	Innovating a leading business model in the real estate development sector.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>5.	Develop distinctive complexes that serve as a landmark in the real estate sector.</p>
                    </div>
                </Row>
                <Row name="#6" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Cooperative Capital:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>. •	The founding capital consists of AED 25 million.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	It is 250 thousand shares at a rate of 100 dirhams per share.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	The founders subscribed with 23,750 shares worth AED 2,375,000.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	The remaining 226,250 shares valued at AED 22,625,000 are open for public subscription.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	The IPO started on October 1st, 2023.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	The Subscription fee is AED 3 per share.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  •	The Public Subscription is currently open through the cooperative website <a href='https://www.housingcoop.ae'>housingcoop.ae</a></p>
                    </div>
                </Row>
            </div>,
            <Row>

                <Col span={14}><div style={{ textAlign: 'center' }}>
                    <div>
                        <Iframe
                            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.4109465421398!2d55.3771502250281!3d25.32398772655427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5b14e6981ba1%3A0xfabc0fc0a9abd168!2zVUFFIEhvdXNpbmcgQ29vcCAtINiq2LnYp9mI2YbZitipINin2YTYpdmF2KfYsdin2Kog2YTZhNil2LPZg9in2YY!5e0!3m2!1sar!2sae"
                            width="80%"
                            height="300"
                            frameBorder="0"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
                </Col>
                <Col style={{ direction: 'rtl' }} span={10}><div style={{ textAlign: 'center' }}>
                    <h3>Contact us</h3>
                    <a href='tel:0097165468400'><Row justify='center'><h4>97165468400+</h4><img style={{ maxHeight: '25px', marginTop: '10px' }} src='../images/phone.png' /></Row></a>
                    <a href='https://wa.me/971509929002'><Row justify='center'><h4>971509929002+</h4><img style={{ maxHeight: '40px', marginTop: '8px' }} src='../images/whats.png' /></Row></a>
                </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#9B7A39', minHeight: '50px', color: 'white', textAlign: 'center', marginTop: '15px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}> All rights reserved to Emirates Housing and Real Estate Development Cooperative</div>
        </div>
    )
}
export default HomePageEn