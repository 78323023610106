import React from 'react'
import { Row, Col, Carousel } from 'antd';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

const locales = {
    en: { title: 'English' },
    ar: { title: 'Arabic' },
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const HomePage = () => {
    const { t } = useTranslation();
    return (
        <div >
            <div
                style={{ backgroundColor: "", height: "85vh", textAlign: "center" }}
                block="true"
            >

                <Carousel
                    autoplay
                    autoplaySpeed={2000}
                    effect="scrollx"
                    draggable="true"
                    dotPosition="bottom"
                >
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/0-banner.jpg" alt="Slide 1-mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/0-banner.jpg" alt="Slide 1" />
                    </div>

                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/1-banner.jpg" alt="Slide 1-mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/1-banner.jpg" alt="Slide 1" />
                        <Link to="/Ar/Stocks">
                            <button className="custom-button">اكتتب الآن</button>
                        </Link>
                    </div>
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/2-banner.jpg" alt="Slide 2 mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/2-banner.jpg" alt="Slide 2" />
                        <Link to="/Ar/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                    <div className="image-box">
                        <Col s xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/3-banner.jpg" alt="Slide 3 mobile" />
                        </Col>
                        <img className='boxing-image' src="/../images/3-banner.jpg" alt="Slide 3" />
                        <Link to="/Ar/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                    <div className="image-box">
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <img style={{ objectPosition: 'top' }} src="/../images/mobile-uae/5-banner.jpg" alt="Slide 4 mobile" />
                        </Col>
                        <img className='boxing-image' style={{ objectPosition: 'top right' }} src="/../images/5-banner.jpg" alt="Slide 4" />
                        <Link to="/Ar/Stocks"> <button className="custom-button">اكتتب الآن</button></Link>
                    </div>
                </Carousel>

            </div>




            <div className='first-subject'>
                <Row name="#1">
                    <Col xs={24} sm={8} md={5}><div style={{ textAlign: 'center' }}>
                        <img style={{ maxWidth: '300px', width: '100%' }} src='/../images/head.png' alt='h' />
                    </div>
                    </Col>
                    <Col sm={16} md={19}><div style={{ textAlign: 'right' }}>
                        <p style={{ fontSize: '1.4em', padding: '35px', fontWeight: '400' }}>“لقد كان رأس اهتمامات المسيرة الاتحادية الخيرة منذ إعلانها؛ الاهتمام بالمواطن،
                            وتهيئة كافة الظروف التي تكفل له الأمن والرفاهية والاستقرار، وفي سبيل ذلك
                            لم تدخر قيادتنا الرشيدة أي جهد وأموال حتى تمكنت بتوفيق من الله تحقيق هذه الغاية”</p>
                        <p style={{ color: '#9B7A39', fontWeight: '400', fontSize: '1.3em', padding: '0 35px 0 0' }}>صاحب السمو الشيخ محمد بن زايد آل نهيان رئيس دولة الإمارات العربية المتحدة حفظه الله</p>
                    </div>
                    </Col>
                </Row>
                <Row name="#2" style={{ textAlign: 'right' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '800' }}>الغاية من التعاونية : </p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>انطلاقاً من الرؤية الحكومية بتطوير قطاع الإسكان في الدولة لمرحلة جديدة من التنمية المستدامة تتكامل فيها الجهود والأدوار وتتعاون فيها الجهات
                            لتحسين الوضع السكني ودعم متطلباته وفق رؤية وطنية هدفها الأول والأخير هو رفاهية وسعادة المواطنين الذين يشكلون أولوية المرحلة القادمة من التنمية الوطنية
                            انطلقت تعاونية الإمارات للإسكان والتطوير العقاري لتقوم بتطوير وإنشاء الفلل والبنايات والمجمعات السكنية والمرافق التجارية برأسمال تأسيسي وقدره 25 مليون درهم،
                            ومقرها العاصمة أبوظبي مع فروع لها في كافة الإمارات.</p>
                    </div>
                </Row>
                <Row name="#3" style={{ textAlign: 'right' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '800' }}>أهم ميزات التعاونية :</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>1- عضويتها مقتصرة على مواطني الدولة</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>2- أسهم رأس مال التعاونية غير محدد العدد ويمكن زيادتها بشكل مستمر وكلما اقتضت
                            الحاجة لذلك</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>3- متعاملي التعاونية هم نفسهم أعضاءها الأمر الذي يوفر الكثير من الجهود التسويقية لبيع خدماتها ومنتجاتها</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>4- يستفيد أعضاء التعاونية من مساهمتهم في الجمعية من خلال أرباح أسهمهم إضافة إلى الأرباح الموزعة عليهم حسب تعاملهم مع التعاونية</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>5- تبعية التعاونية لوزارة الاقتصاد ودائرة التنمية الاقتصادية الأمر الذي يجعلها خاضعة للإشارف الحكومي مما يزيد من انضباطها وشفافية إجراءاتها وخضوعها لأفضل الممارسات المالية</p>
                    </div>
                </Row>
                <Row name="#4" style={{ textAlign: 'right' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '800' }}>الخدمات التي تقدمها التعاونية لأعضائها:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• بناء التعاونية للفلل السكنية الخاصة بأعضائها والمقامة على أراضي منحة أو أراض مملوكة للأعضاء.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• تطوير التعاونية لمشاريع عقارية تجارية وسكنية وتوزيعها على الأعضاء.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• قيام التعاونية بإدارة وتأجير وصيانة البنايات والشقق والفلل والوحدات التجارية الخاصة بالأعضاء.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>• السعي على إيجاد الأراضي السكنية اللازمة وتقسيمها لإقامة مساكن عليها للأعضاء.</p>
                    </div>
                </Row>
                <Row name="#5" style={{ textAlign: 'right' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '800' }}>أهداف التعاونية:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>1- المساهمة في بناء المساكن على الارضي الممنوحة للمواطنين من قبل الحكومة بأفضل تكلفة</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>2- توفير الشقق والفلل والوحدات السكنية والتجارية للمواطنين بأسعار مناسبة وتنافسية</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>3- المساهمة في توفير فرص استثمارية عقارية مجدية للمواطنين</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>4- ابتكار نموذج وكيان رائد في قطاع التطوير العقاري</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>5- تطوير مجمعات عمرانية متميزة تكون نموذج يحتذى في القطاع العقاري</p>
                    </div>
                </Row>
                <Row name="#6" style={{ textAlign: 'right' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '800' }}>رأس مال التعاونية :</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>يتكون رأس المال التأسيسي من 25 مليون درهم.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}> وهي عبارة عن 250 ألف سهم بواقع 100 درهم لكل سهم.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}> اكتتب المؤسسون وهم 19 شخصية من كبار الشخصيات بـ 23,750 سهم قيمتها 2,375,000 درهم</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}> سيتم الاكتتاب على أسهم التأسيس المتبقية والبالغة 226,250 سهم وقيمتها 22,625,000 درهم</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}> يبدأ الاكتتاب العام اعتباراً من الأول من شهر أكتوبر من عام 2023.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>   رسوم الاكتتاب 3 درهم عن كل سهم.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>  طريقة الاكتتاب من خلال موقع التعاونية <a href='https://www.housingcoop.ae'>housingcoop.ae</a></p>
                    </div>
                </Row>
            </div>,
            <Row>

                <Col span={14}><div style={{ textAlign: 'center' }}>
                    <div>
                        <Iframe
                            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.4109465421398!2d55.3771502250281!3d25.32398772655427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5b14e6981ba1%3A0xfabc0fc0a9abd168!2zVUFFIEhvdXNpbmcgQ29vcCAtINiq2LnYp9mI2YbZitipINin2YTYpdmF2KfYsdin2Kog2YTZhNil2LPZg9in2YY!5e0!3m2!1sar!2sae!4v1698060593197!5m2!1sar!2sae"
                            width="80%"
                            height="300"
                            frameBorder="0"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
                </Col>
                <Col span={10}><div style={{ textAlign: 'center' }}>
                    <h3>تواصل معنا</h3>
                    <a href='tel:0097165468400'><Row justify='center'><h4>97165468400+</h4><img style={{ maxHeight: '25px', marginTop: '10px' }} src='../images/phone.png' /></Row></a>
                    <a href='https://wa.me/971509929002'><Row justify='center'><h4>971509929002+</h4><img style={{ maxHeight: '40px', marginTop: '8px' }} src='../images/whats.png' /></Row></a>
                </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#9B7A39', minHeight: '50px', color: 'white', textAlign: 'center', marginTop: '15px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}> جميع الحقوق محفوظة لتعاونية الإمارات للإسكان والتطوير العقاري</div>
        </div>
    )
}
export default HomePage