import { Col, Form, Input } from 'antd'
import React from 'react'

const FormInputText = (props) => {
    const {
        span,
        name,
        label,
        required,
        message,
        defaultValue,
        ...other
    } = props
    return (
        <Col span={span}>
            <Form.Item
                label={label}
                name={name}
                rules={[{ required: required, message: message ? message : "الرجاء إدخال "+label }]}
            >
                <Input defaultValue={defaultValue} {...other} />
            </Form.Item>
        </Col>
    )
}

export default FormInputText