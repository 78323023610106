import React from 'react'
import { Row, Col, Carousel, Button } from 'antd';
import GoogleMapReact from 'google-map-react';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const News = () => {
    return (
        <div style={{padding: '0 20px'}}>
                    <h1>
                أخبار التعاونية
            </h1>
        <div style={{ textAlign: 'center', padding: '0', minHeight: '82vh',fontWeight:'400',fontSize:'1.6m' }}>
            <Row justify="center">
                <Col md={15}>
                    <h2>بدء الاكتتاب في أسهم "تعاونية الإمارات للإسكان والتطوير العقاري"</h2>
                <a href="https://tinyurl.com/3eabjsmz"><img style={{maxWidth:'100%', padding:'25px', borderRadius:'35px'}} src='../images/news6.png'/></a>
                </Col>
                <Col md={15}>
                <a href="http://eti.ae/IbLs"><img style={{maxWidth:'100%', maxHeight:'400px',padding:'25px', borderRadius:'35px'}} src='../images/news5.jpeg'/></a>
                </Col>
                <Col md={10}>
                <a href="https://tinyurl.com/4ems72ry"><img style={{maxWidth:'100%',padding:'25px', borderRadius:'35px'}} src='../images/news1.jpeg'/></a>
                </Col>
                <Col md={10}>
                <a href="http://eti.ae/IbLs"><img style={{maxWidth:'100%',padding:'25px', borderRadius:'35px'}} src='../images/news2.jpeg'/></a>
                </Col>
                <Col md={10}>
                <a href="https://www.albayan.ae/economy/uae/2023-10-04-1.4739044"><img style={{maxWidth:'100%', borderRadius:'35px'}} src='../images/news3.jpeg'/></a>
                </Col>
                <Col md={10}>
                <a href="https://alwatan.ae/archives/1217769"><img style={{maxWidth:'100%',padding:'25px', borderRadius:'35px'}} src='../images/news4.jpeg'/></a>
                </Col>
            </Row>
        </div>
        </div>
    )
}

export default News