import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import HomePage from "../pages/ar/homePage/HomePage";
import HomePageEn from "../pages/en/homePage/HomePage";
import MainLayout from "../layout/mainLayout/MainLayout";
import MainLayoutEn from "../layout/mainLayout/MainLayoutEn";

import About from "../pages/ar/about/About";
import News from "../pages/ar/news/News";
import Stocks from "../pages/ar/stocks/Stocks";
import Register from "../pages/ar/register/Register";
import Buy from "../pages/ar/stocks-old/Stocks-old"
import Login from "../pages/ar/login/Login";
import UpdateUser from "../pages/ar/updateuser/UpdateUser"

import AboutEn from "../pages/en/about/About";
import NewsEn from "../pages/en/news/News";
import StocksEn from "../pages/en/stocks/Stocks";
import RegisterEn from "../pages/en/register/Register";
import BuyEn from "../pages/en/stocks-old/Stocks-old"
import LoginEn from "../pages/en/login/Login";
import UpdateUserEn from "../pages/ar/updateuser/UpdateUser"
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'


const AppRoute = (prop) => {

    const [token, setToken] = useState();
    const [completed, setCompleted] = useState();
    const location = useLocation()
    const [props, setProps] = useState(prop);
    //let props = {...prop,token:token}
    useEffect(() => {
        let Mytoken = window.localStorage.getItem('token')
        let Mycompleted = window.localStorage.getItem('Is_completed')
        setToken(JSON.parse(Mytoken))
        setCompleted(JSON.parse(Mycompleted))
        if (Mytoken) {
            Mytoken = Mytoken.replace(/^"|"$/g, '');
        }
        //console.log("Mycompleted",Mycompleted);
        //console.log("Mytoken",Mytoken);
        //props = {...props,token:Mytoken}
        setProps({ ...props, token: Mytoken, completed: Mycompleted })
    }, [window.localStorage, location, location?.pathname])

    useEffect(() => {
        console.log("completed", completed);
    }, [completed])

    const AuthRout = (propses) => {
        const { condtion } = propses
        if (condtion) {
            return (
                propses.children
            )
        } else {
            <MainLayout>
                <HomePage {...props} />
            </MainLayout>
        }


    }


    return (


        <Routes>
            <Route
                exact
                path="/Ar/HomePage"
                element={
                    <MainLayout>
                        <HomePage {...props} />
                    </MainLayout>
                }
            />
            <Route
                exact
                path="/Ar/About"
                element={
                    <MainLayout>
                        <About {...props} />
                    </MainLayout>
                }
            />

            <Route
                exact
                path="/Ar/News"
                element={
                    <MainLayout>
                        <News {...props} />
                    </MainLayout>
                }
            />


            <Route
                exact
                path="/Ar/Stocks"
                element={
                    <Stocks {...props} />
                }
            />

            <Route
                exact
                path="/Ar/Register"
                element={
                    <MainLayout>
                        <Register {...props} />
                    </MainLayout>
                }
            />

                <Route
                    exact
                    path="/En/HomePage"
                    element={
                        <MainLayoutEn>
                            <HomePageEn {...props} />
                        </MainLayoutEn>
                    }
                />
                <Route
                    exact
                    path="/En/About"
                    element={
                        <MainLayoutEn>
                            <AboutEn {...props} />
                        </MainLayoutEn>
                    }
                />

                <Route
                    exact
                    path="/En/News"
                    element={
                        <MainLayoutEn>
                            <NewsEn {...props} />
                        </MainLayoutEn>
                    }
                />


                <Route
                    exact
                    path="/En/Stocks"
                    element={
                        <StocksEn {...props} />
                    }
                />

                <Route
                    exact
                    path="/En/Register"
                    element={
                        <MainLayoutEn>
                            <RegisterEn {...props} />
                        </MainLayoutEn>
                    }
                />

                {/* Redirect to the home page if the link is unknown */}
                <Route
                    path="*"
                    replace
                    element={
                        <Navigate
                            to={`/Ar/HomePage`}
                            replace={true}
                        />
                    }
                />
            </Routes>





            );
};

            export default AppRoute;