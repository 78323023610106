import React from 'react'
import { useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Upload, InputNumber, Typography,message, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import FormInputText from '../../../component/formInputText/FormInputText';
import { useForm } from 'antd/es/form/Form';
import { fetchFromUrlPOSTAsync } from '../../../actions/Global';
import dayjs from 'dayjs';
import UploadImage from '../../../component/uploadFile/UploadImage';
import FormItem from 'antd/es/form/FormItem';

const { Title } = Typography;




const Stocksold = (props) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    let [shares, setShares] = useState(5);
    const { token } = props
    const onFinish = async (values) => {
        console.log(values);
        if (values?.expiry) {
            values.expiry = dayjs(values.expiry).format("DD-MM-YYYY")
        }
        let result = await fetchFromUrlPOSTAsync("stocks/store", values, token)
        if (result) {
            console.log("result", result);
            message.info(result.data.message);
        }
    }

    const onFileChange = ({ fileList }) => {
        setFileList(fileList);
    };



    const calculateTotalPrice = () => {
        return shares * 100;
    };

    const calculateSubscriptionFee = () => {
        return ((shares * 100) * 0.03);
    };

    const calculateGrandTotal = () => {
        return calculateTotalPrice() + calculateSubscriptionFee();
    };

    const handleIncrement = () => {
        if (shares < 25000) {
            setShares(shares + 1);
            form.submit();
        }
    };

    const handleDecrement = () => {
        if (shares > 5) {
            setShares(shares - 1);
            form.submit();
        }
    };

    function viewpayment() {
        var elements = document.getElementsByClassName('Payment');
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].style.display === 'none') {
                elements[i].style.display = 'block';
            }
            else {
                elements[i].style.display = 'none';
            }
        }
    }

    return (
        <div className='stocks' style={{ backgroundColor: '#2D343C', width: '80%', padding: '25px', margin: '5% auto', borderRadius: '25px', textAlign: 'center' }}>
            <div style={{ maxWidth: '70%', textAlign: 'center', color: '#fff', margin: '0 15%' }}>
                <Title level={3}><span style={{ lineHeight: '200%', color: '#fff' }}>سعر السهم الواحد: 100 درهم</span></Title>
                <Form form={form} onFinish={onFinish}>

                    <div>
                        <Form.Item name="amount">
                            <Row>
                                <Col span={6}>
                                    <Button
                                        style={{display:'none' ,fontSize: '2em', textAlign: 'center', height: 'auto', border: 'none', color: '#e74545', backgroundColor: '#2d343c' }}
                                        onClick={handleDecrement}>-</Button>
                                </Col>
                                <Col span={12}>
                                    <Input
                                        name='amount'
                                        style={{ fontSize: '1.5em', textAlign: 'center', height: 'auto' }}
                                        type="number"
                                        defaultValue={shares} // القيمة الابتدائية
                                        min={5}
                                        max={25000}
                                        onChange={(e) => setShares(Number(e.target.value))}
                                        value={shares}
                                    />
                                    <div style={{ padding: '10px', color: 'red' }} className="error">
                                        {(shares < 5 || shares > 25000) && 'القيمة يجب أن تكون بين 5 و 25000'}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Button
                                        style={{display:'none' ,fontSize: '2em', textAlign: 'center', height: 'auto', border: 'none', color: '#45e745', backgroundColor: '#2d343c' }}
                                        onClick={handleIncrement}>+</Button>
                                </Col>
                            </Row>
                        </Form.Item>
                        <div style={{ textAlign: 'center', fontSize: '1.3em', lineHeight: '250%', color: '#fff' }}>
                            <div>
                                <span>سعر الأسهم: {calculateTotalPrice()} درهم</span>
                            </div>
                            <div>
                                <span>رسوم الاكتتاب: {calculateSubscriptionFee()} درهم</span>
                            </div>
                           
                        </div>
                    </div>
                    <Button style={{ marginTop: '3%' }} onClick={viewpayment}>دفع الرسوم</Button>
                    <div style={{ padding: '15px', display: 'none', padding: '5% 15%' }} className='Payment'>

                        <Form.Item
                            name="attachments"
                        >
                            <UploadImage
                                onChange={(e) => { form.setFieldValue("attachments", e) }}
                            />
                            <p style={{ color: '#9b7a39' }}>يرجى إرفاق صورة لإيصال الدفع في البنك من أجل ثمن الأسهم:  {calculateTotalPrice()} درهم</p>
                        </Form.Item>

                        <p style={{ color: '#9b7a39' }}>يرجى إدخال معلومات البطاقة من أجل دفع رسوم الإكتتاب: {calculateSubscriptionFee()} درهم</p>
                        <Form.Item
                            label="Pan (رقم البطاقة)"
                            name="pan"
                            style={{ textAlign: 'right' }}
                            labelCol={{ span: 8 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'الرجاء إدخال رقم البطاقة',
                                },
                                {
                                    pattern: /^[0-9]{16}$/,
                                    message: 'رقم البطاقة يجب أن يتكون من 16 رقمًا',
                                },
                            ]}
                        >
                            <Input placeholder="4111111111111111" />
                        </Form.Item>

                        <Form.Item
                            label="تاريخ الانتهاء"
                            style={{ textAlign: 'right' }}
                            labelCol={{ span: 8 }}
                            name="expiry"
                            rules={[
                                {
                                    required: true,
                                    message: 'الرجاء إدخال تاريخ الانتهاء',
                                },
                            ]}
                        >
                            <DatePicker format="MM/YYYY" style={{ width: '100%' }} placeholder="MM/YYYY" />
                        </Form.Item>

                        <Form.Item
                            label="CVV"
                            labelCol={{ span: 8 }}
                            style={{ textAlign: 'right' }}
                            name="cvv"
                            rules={[
                                {
                                    required: true,
                                    message: 'الرجاء إدخال رمز CVV',
                                },
                                {
                                    pattern: /^[0-9]{3}$/,
                                    message: 'رمز CVV يجب أن يتكون من 3 أرقام',
                                },
                            ]}
                        >
                            <Input placeholder="123" />
                        </Form.Item>

                        <Form.Item
                            label="اسم حامل البطاقة"
                            labelCol={{ span: 8 }}
                            style={{ textAlign: 'right' }}
                            name="card_holder_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'الرجاء إدخال اسم حامل البطاقة',
                                },
                            ]}
                        >
                            <Input placeholder="john doe" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                إرسال
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );

}

export default Stocksold;