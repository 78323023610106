import React from 'react'
import { Row, Col, Carousel, Button } from 'antd';
import GoogleMapReact from 'google-map-react';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const About = () => {
    return (
        <div style={{ textAlign: 'center', padding: '75px', minHeight: '82vh',fontWeight:'400',fontSize:'1.5em' }}>
            <p>انطلاقاً من الرؤية الحكومية بتطوير قطاع الإسكان في الدولة لمرحلة جديدة من التنمية المستدامة </p>
            <p>تتكامل فيها الجهود والأدوار وتتعاون فيها الجهات لتحسين الوضع السكني ودعم متطلباته وفق رؤية وطنية هدفها الأول والأخير هو رفاهية وسعادة المواطنين</p>
            <p> الذين يشكلون أولوية المرحلة القادمة من التنمية الوطنية</p>
            <p> انطلقت تعاونية الإمارات للإسكان والتطوير العقاري لتقوم بتطوير وإنشاء الفلل والبنايات والمجمعات السكنية والمرافق التجارية</p>
            <p>برأسمال تأسيسي وقدره 25 مليون درهم، ومقرها العاصمة أبوظبي مع فروع لها في كافة الإمارات.</p>
        </div>
    )
}

export default About