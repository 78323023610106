import axios from "axios";

export const getBaseURL = () => {
  return `https://housing.widespheres.com/api/`;
};


const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    Accept:
      "application/json;charset=UTF-8",
      "Content-Type": "application/json",
  },
  validateStatus: false,
  withCredentials: false,
  timeout: 20000,
});


export async function fetchFromUrlPOSTAsync(url, dataPost ={},token) {
  try {
   
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.post(url, dataPost);
    return result;

  } catch (error) {
    console.log("error = ", error);
    return { success: false, errMsg: error.message };
  }
}

export async function fetchFromUrlGETAsync(url, dataPost ={},token) {
  try {

    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.get(url, {
      params: dataPost,
    });
    return result;
  } catch (error) {
    console.log("error = ", error);
    return { success: false, errMsg: error.message };
  }
}

export async function fetchFromUrlDELETEAsync(url, dataPost = {},token) {
  try {
 
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.delete(url, {
      params: dataPost,
    });
    return result;
  } catch (error) {
    console.log("error = ", error);
    return { success: false, errMsg: error.message };
  }
}

