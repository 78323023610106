import { Avatar, Button, Layout, Row, Tabs, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import './MainLayout.css'
import { Content, Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


const MainLayoutEn = (props) => {

  const [text, setText] = useState('En');
  const [activetab, setActiveTab] = useState('1');

  const [clicked, setClicked] = useState(false);
  const location  = useLocation()
  const [token, setToken] = useState();
  const [completed, setCompleted] = useState();
    useEffect(() => {
        const Mytoken = window.localStorage.getItem('token')
        let Mycompleted = window.localStorage.getItem('Is_completed')
        setToken(JSON.parse(Mytoken))
        setCompleted(JSON.parse(Mycompleted))
        //console.log("Mytoken",Mytoken);
    }, [window.localStorage,location,location?.pathname])

const logout = ()=>{
  localStorage.setItem("token",null)
  localStorage.clear();
  navigate("/HomePage")
  setToken(null)
}
  const handleAvatarClick = () => {
    setClicked(!clicked);
    var logoElements = document.getElementsByClassName('logo');
    var langElements = document.getElementsByClassName('lang');

    if (clicked) {
      setText('En');
      //document.body.style.direction = 'rtl';

      for (let i = 0; i < logoElements.length; i++) {
        //logoElements[i].style.textAlign = 'right';
      }
      for (let j = 0; j < langElements.length; j++) {
        //langElements[j].style.textAlign = 'left';
      }
    }
    else {
      setText('Ar');
      //document.body.style.direction = 'ltr';
      for (let i = 0; i < logoElements.length; i++) {
        //logoElements[i].style.textAlign = 'left';
      }
      for (let j = 0; j < langElements.length; j++) {
        //langElements[j].style.textAlign = 'right';
      }

    }
  };

  const navigate = useNavigate()

  const items = [
    {
      key: '1',
      label: 'Main',
      rout: "/En/HomePage"
    },
    {
      key: '2',
      label: 'About',
      rout: "/En/About"

    },
    {
      key: '3',
      label: 'News',
      rout: "/En/News"
    },
    {
      key: '4',
      label: 'Subscription to Cooperative’s Capital shares',
      rout: "/En/Stocks"

    },

  ];
  const onChangeTab = (key) => {
    let ItemFind = items.find(e => e.key === key)
    setActiveTab(key)
    navigate(ItemFind?.rout)
  }
  const modifiedPath = location.pathname.replace('/En', '/Ar');

  return (
    <Layout style={{direction:'ltr'}} className='MainLayout' >
    
        <div className='nav-head'>
          <Row>
            <Col md={10} sm={20} xs={18}>
          <div className='logo'
>
            <a href='\HomePage'><img
              style={{ position: "absolute",maxHeight:'100px', maxWidth:'100%', padding:'5px 5px 0 0'}}
              src="/images/logo192.png" alt="Slide 5" /></a>
          </div>
          </Col>
          <Col md={8} sm={20} xs={18}>
          <Tabs
            position='sticky'
            type='editable-card'
            items={items}
            defaultActiveKey='1'
            activeKey={activetab}
            onChange={onChangeTab}
            className='nav-head2'
            style={{ padding: "3% 3% 0 3%", margin: "-30px 0 0 0 !important", transform:'none !important' }} 
            />
          </Col>
          <Col md={4} sm={4} xs={6}>
          <div className='lang' style={{ padding: "45px 0 0 3px", textAlign: "left", position:'absolute' }}>
          <span style={{ color: '#9B7A39' }}>Language | </span>
            <Link to={modifiedPath}>Ar</Link>
            <Row></Row>
            <div style={{display:'none'}}>
            { token? 
            <Button
              onClick={() => { logout() }}
              type='primary'>تسجيل الخروج</Button>: 
	<a href="https://dash.housingcoop.ae/login" target="_self">
            <Button
             // onClick={() => { navigate("/Login") }}
              type='primary'>Login</Button></a>}
              </div>
          </div>
          </Col>
          </Row>
        </div>



      <Content className='MainLayout_content' >{props.children}</Content>
    </Layout>
  )
}

export default MainLayoutEn