import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import FormInputText from '../../../component/formInputText/FormInputText';
import { useForm } from 'antd/es/form/Form';
import { fetchFromUrlPOSTAsync } from '../../../actions/Global';
import dayjs from 'dayjs';
import UploadImage from '../../../component/uploadFile/UploadImage';
import { useNavigate } from 'react-router-dom';

const Register = (props) => {
    const navigate = useNavigate()
    const { token } = props
    const onFinish = async (values) => {
        //console.log(values);
        if (values?.birthdate) {
            values.birthdate = dayjs(values.birthdate).format("DD-MM-YYYY")
        }
        let result = await fetchFromUrlPOSTAsync("register", values)
        if (result.data.errors) {
            //console.log("result", result);
            message.info(result.data.message);
        }
        else {
            //console.log("result", result);
            message.info(result.data.message);
            setTimeout(function () {
                window.open('https://dash.housingcoop.ae/login', '_self');
            }, 2000);
        }
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const [form] = useForm()


    return (
        <div
            className='stocks' style={{ width: '80%', padding: '25px', margin: '5% 15%', borderRadius: '25px', textAlign: 'center', fontWeight: '400' }}>
            <Form
                style={{ backgroundColor: '#9b7a3966', padding: '25px', width: '90%', borderRadius: '15px' }}
                onFinish={onFinish}
                form={form}
                name={"RegisterForm"}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
            >

                <Row gutter={16} style={{ display: 'inline' }}>
                    <FormInputText
                        md={24}
                        sm={24}
                        name={"email"}
                        label={"البريد الإلكتروني"}
                        required={true}
                    />
                    <FormInputText
                        md={24}
                        sm={24}
                        name={"password"}
                        label={"كلمة المرور"}
                        required={true}
                        type={"password"}
                    />
                    <FormInputText
                        md={24}
                        sm={24}
                        name={"phone"}
                        label={"رقم الموبايل"}
                        required={true}
                    />
                    <FormInputText
                        md={24}
                        sm={24}
                        name={"password_confirmation"}
                        label={"تأكيد كلمة المرور"}
                        required={true}
                        type={"password"}
                    />
                </Row>

                <Row justify="center"> {/* هذا السطر يجعل العناصر في منتصف الصفحة */}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                            form='RegisterForm'
                            style={{ backgroundColor: '#ddd', color: 'black' }} block type="primary" htmlType="submit">
                            تسجيل
                        </Button>
                    </Form.Item>
                </Row>
                <Row justify="center"> {/* هذا السطر يجعل العناصر في منتصف الصفحة */}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <a href='https://dash.housingcoop.ae/login'>لدي حساب بالفعل</a>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

export default Register