import React from 'react'
import { Row, Col, Carousel, Button } from 'antd';
import GoogleMapReact from 'google-map-react';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const About = () => {
    return (
        <div style={{ textAlign: 'center', padding: '75px', minHeight: '82vh',fontWeight:'400',fontSize:'1.5em' }}>
            <p>Based on the Government's vision to develop the housing sector in the country for a new phase of sustainable development; in which the efforts and roles are integrated and the parties cooperate to improve the housing situation and support its requirements in accordance with a national vision. </p>
            <p>The first and final objective of this vision is the prosperity and happiness of citizens who are the priority of the next phase of national development.</p>
            <p> Emirates Cooperative for Housing and Real Estate Development has been launched to develop and build villas, buildings, residential buildings commercial buildings, and facilities with a capital of 25 million, Based in Abu Dhabi with branches across the UAE.</p>
        </div>
    )
}

export default About