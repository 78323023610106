import React, { useState } from 'react';
import Modal from 'react-modal';
import { Row, Col, Carousel, Button } from 'antd';
import GoogleMapReact from 'google-map-react';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const Stocks = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };
    return (
        <div style={{direction:'ltr'}}>
            <div>
                <Link to="/En/HomePage">
                    <Button type='primary' style={{ position: 'fixed', left: '5px', top: '5px' }}>Back</Button>
                </Link>
                <Row>
                    <div
                        style={{ backgroundColor: "", height: "100%", textAlign: "center" }}
                        block="true"
                    >
                        <img
                            style={{
                                width: 'auto',
                                objectPosition: 'top left', // تحديد مكان الصورة في الأعلى واليمين
                                objectFit: 'cover',
                                minHeight: '100px',
                                maxWidth: '100%', // تمنع تجاوز العرض على الشاشة الصغيرة
                            }}
                            src="../images/Artboard-1-100.jpg"
                            alt="صورة"
                        />
                    </div>
                </Row>
            </div>

            <div className='first-subject'>
                <Row name="#2" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ fontSize: '1.4em', fontWeight: '400' }}>Welcome to the subscription page on the shares of the Emirates Cooperative for Housing and Real Estate Development (under formation)</p>
                    </div>
                </Row>
                <Row name="#3" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Cooperative’s Capital:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>AED 25 million spread over 250,000 shares (two hundred and fifty thousand shares)</p>
                    </div>
                </Row>
                <Row name="#4" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>The founders subscribed at:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}> 23,750 shares in the amount of 2,375,000</p>
                    </div>
                </Row>
                <Row name="#5" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Shares available for subscription:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>226,250 shares worth AED 22,625,000 at AED 100 per share</p>

                    </div>
                </Row>
                <Row name="#6" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Minimum subscription:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>5 shares in the amount of AED 500</p>
                    </div>
                </Row>
                <Row name="#7" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Maximum subscription limit:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>25,000 shares worth AED 2,500,000</p>
                    </div>
                </Row>
                <Row name="#8" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Subscription fees:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>AED 3 per share</p>
                    </div>
                </Row>
                <Row name="#9" style={{ textAlign: 'left' }}>
                    <div>
                        <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Subscription Terms:</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>1.	To be a citizen of the United Arab Emirates.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>2.	Not to be a member of another cooperative Society exercising the same purposes of this coop.</p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>3.	To accept the statute of the Cooperative Society. </p>
                        <p style={{ fontSize: '1.4em', fontWeight: '300' }}>4.	At least 18 years of age shall be complete.</p>
                    </div>
                </Row>

                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xs={8} md={8} style={{ fontSize: '1.4em', fontWeight: '300', textAlign: 'center' }}>
                        <a href='https://housingcoop.ae/downloads/النظام-الاساسي-لتعاونية-الإسكان.pdf'>
                            <img style={{ maxHeight: '120px', maxWidth: '80%' }} src='/../images/pdf.png' />
                        </a>
                        <Row justify="center">
                        <span>Statute</span>
                        </Row>
                    </Col>
                    <Col xs={8} md={8} style={{ fontSize: '1.4em', fontWeight: '300', textAlign: 'center' }}>
                        <a href='https://housingcoop.ae/downloads/نشرة-الاكتتاب.pdf'>
                            <img style={{ maxHeight: '120px', maxWidth: '80%' }} src='/../images/pdf.png' />
                        </a>
                        <Row justify="center">
                        <span>Subscription Prospectus</span>
                        </Row>
                    </Col>
                    <Col xs={8} md={8} style={{ fontSize: '1.4em', fontWeight: '300', textAlign: 'center' }}>
                        <a href='https://housingcoop.ae/downloads/دراسة-الجدوى-كاملة-بالعربي-بالتوصية.pdf'>
                            <img style={{ maxHeight: '120px', maxWidth: '80%' }} src='/../images/pdf.png' />
                        </a>
                        <Row justify="center">
                        <span>Feasibility Study</span>
                        </Row>
                    </Col>
                </Row>






                    <p style={{ color: '#9B7A39', fontSize: '1.8em', fontWeight: '700' }}>Subscription Mechanism:</p>

                    <Row name="#11" style={{ textAlign: 'left' }}>

                        <Col md={12} sm={24} xs={24}>
                            <div>
                                <img style={{ width: '100%', maxHeight: '400px' }} src='/../images/stocks.png' />
                            </div>
                        </Col>
                        <Col span={3}></Col>
                        <Col>
                        <a href="#" onClick={openModal}>
  <Button
    style={{
      backgroundColor: '#a5781f',
      padding: '50px',
      borderRadius: '50%',
      fontSize: '1.8em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100px',
      marginTop: '25%',
    }}
    type="primary"
  >
                        Start the subscription
  </Button>
</a>
                        </Col>
                    </Row>
            </div>,
            <Modal
//   className="modal"
  isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="اكتتاب الأسهم"
        style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                height: '30%',
              borderRadius: '10px',
              padding: '40px',
              margin: '40px',
              textAlign: 'center',
            },
        }}
      >
        <h2>The subscription for Housing Coop Stocks has been closed</h2>
        <p></p>
        <p>
        The subscription for Housing Coop Stocks has been closed. If you are interested in purchasing coop stocks, please register your interest <a href="https://api.whatsapp.com/send?phone=00971509929002">here </a>
        or log in to your existing account and click              <a href="https://dash.housingcoop.ae/login" target="_self">
        here </a>
        </p>
        <button onClick={closeModal}>إغلاق</button>
      </Modal>
        </div>
    )
}

export default Stocks